<template>
  <a-result status="404" title="404" sub-title="抱歉，您访问的页面不存在。">
    <template #extra>
      <a-button type="primary" @click="toHome">
        返回首页
      </a-button>
    </template>
  </a-result>
</template>

<script>
export default {
  name: 'Exception404',
  methods: {
    toHome() {
      if (this.$store.getters.userRole === 'registered_agent') {
        this.$router.push({ path: '/registered_agents/home' })
      } else {
        this.$router.push({ path: '/' })
      }
    }
  }
}
</script>
